@font-face {
  font-family: 'DIN Next Rounded';
  src: url(/static/media/DINNextRoundedLTPro-Light.aa5d4f32.woff2) format('woff2'),
    url(/static/media/DINNextRoundedLTPro-Light.1afd680d.woff) format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next Rounded';
  src: url(/static/media/DINNextRoundedLTPro-Regular.fb022e15.woff2) format('woff2'),
    url(/static/media/DINNextRoundedLTPro-Regular.500ffd70.woff) format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next Rounded';
  src: url(/static/media/DINNextRoundedLTPro-Medium.7a3638ed.woff2) format('woff2'),
    url(/static/media/DINNextRoundedLTPro-Medium.ee217128.woff) format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next Rounded';
  src: url(/static/media/DINNextRoundedLTPro-Bold.0009bf96.woff2) format('woff2'),
    url(/static/media/DINNextRoundedLTPro-Bold.153ab2ff.woff) format('woff');
  font-weight: 700;
  font-display: swap;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: ' ';
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  height: 100%;
  font-family: 'DIN Next Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  color: #4a4a49;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.375;
}

h1,
.PageHeader {
  margin: 0.6em 0;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
}

h2,
.SubHeader {
  margin: 0.5em 0;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.167;
}

h3 {
  margin: 0.4em 0;
  font-weight: 500;
  font-size: 1.8rem;
}

h4 {
  font-weight: 500;
}

* + p {
  margin-top: 1em;
}

ul,
ol {
  margin: 1em 1em 1em 0;
  padding-left: 1em;
  list-style-type: disc;
}

a {
  font-weight: 400;
  color: #ef7026;
}

a:not(.Button):hover {
  color: #f58544;
}

[hidden] {
  display: none !important;
}

.Text--error {
  color: #d03939;
  font-weight: 400;
}

hr {
  position: relative;
  margin: 3rem 0 4rem;
  border-top: 1px dashed #ef7026;
}

hr:after {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: block;
  left: calc(50% - 1rem);
  top: -1rem;
  background: #fff;
  border-right: 1px dashed #ef7026;
  border-bottom: 1px dashed #ef7026;
  transform: rotate(45deg);
}

.PageContainer {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 2rem;
}

.Section {
  position: relative;
  margin-bottom: 4rem;
}

.Section:after {
  content: '';
  display: table;
  clear: both;
}

.Section .Button--cancel {
  position: absolute;
  left: 0;
  bottom: 0;
}

.PageNavGroup {
  text-align: right;
}

.PageNavGroup .Button {
  vertical-align: top;
  display: inline-block;
  margin-left: 1rem;
}

label {
  font-weight: 500;
}

input {
  height: 36px;
  padding: 0 10px;
  border: 1px solid transparent;
  background: #f7f7f7;
  font-weight: 500;
  line-height: 36px;
  color: #4a4a49;
  transition: border-color 0.15s ease-in-out;
}

input:focus {
  outline: 0;
  border-color: #ef7026;
}

:not(output):-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-input-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}
::-moz-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}
:-moz-placeholder {
  color: #e2e2e2;
  font-weight: 300;
}

input[type='checkbox'],
input[type='radio'] {
  height: 1px;
  left: -10000px;
  position: absolute;
  width: 1px;
  zoom: 1;
  filter: alpha(opacity=0);
  opacity: 0;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  position: relative;
  padding-left: 3rem;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  content: '';
  border: 1px solid #ef7026;
  background: transparent;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  transition: background-color 0.15s ease-in-out;
}

input[type='checkbox']:focus + label:before,
input[type='checkbox'] + label:hover:before,
input[type='radio']:focus + label:before,
input[type='radio'] + label:hover:before {
  border-width: 2px;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  background: #ef7026;
}

input[type='radio'] + label:before {
  border-radius: 100%;
}

input:read-only,
textarea:read-only,
[contenteditable]:read-only {
  background: #fafafa;
  cursor: not-allowed;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

/* Suppress focus ring on buttons for mouse users */
[data-whatintent='mouse'] button:focus {
  outline: 0;
}

legend {
  margin-bottom: 2rem;
}

.RadioGroup .Radio {
  margin-bottom: 1rem;
}

.FormSection {
  position: relative;
  padding: 2rem 0;
  border: 0;
}

.FormSection + .FormSection {
  padding-top: 3rem;
  border-top: 1px dashed #ef7026;
}

.FormSection + .FormSection:before {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: block;
  left: calc(50% - 1rem);
  top: -1rem;
  background: #fff;
  border-right: 1px dashed #ef7026;
  border-bottom: 1px dashed #ef7026;
  transform: rotate(45deg);
}

.FormSection legend {
  margin-left: -1rem;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 2rem;
}

.FormSection legend .Alert {
  display: inline-block;
}

.Form-buttongroup {
  float: right;
  text-align: right;
}

.Form-buttongroup .Button {
  margin-left: 1rem;
}

.Form-button {
  float: right;
  margin-left: 1rem;
}

/* Overwrite React Select default stylings */

.Select-control {
  background-color: #f7f7f7;
  border: 1px solid transparent;
  border-radius: 0;
  color: #4a4a49;
}

.Select.is-open > .Select-control {
  background: #f7f7f7;
  border-color: #ef7026;
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
  border: 1px solid #ef7026;
}

.Select--multi .Select-value {
  margin: 4px 0 0 4px;
  background-color: #fff;
  color: #ef7026;
  border-color: #ef7026;
}

.Select-option.is-focused {
  background-color: #ef7026;
  color: #fff;
}

.Select--multi .Select-value-icon {
  color: #ef7026;
  border-right-color: #ef7026;
}

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: #ef7026;
  color: #fff;
}

.Select-value {
  font-weight: 400;
}

.Select-clear-zone:hover {
  color: #ef7026;
}

.Select-clear {
  font-size: 30px;
}

.Select.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #ef7026;
}

.Select.is-open > .Select-control .Select-arrow {
  border-bottom-color: #ef7026;
}

.Select-menu-outer {
  border-radius: 0;
  border-color: #ef7026;
}

.Select-input > input {
  height: 34px;
  padding: 0;
}

.SelectedList {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.SelectedList li {
  position: relative;
  padding: 0.5rem 2.5rem 0.5rem 3.5rem;
  transition: background-color 0.2s ease-out;
}

.SelectedList li .Alert {
  display: inline-block;
}

.SelectedList li .Button {
  width: 100%;
  text-align: left;
  color: #4a4a49;
  letter-spacing: normal;
  font-weight: 300;
}

.SelectedList li.is-invalid .Button {
  color: #ee3d48;
}

.SelectedList li:before {
  content: '\2713';
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background: #ef7026;
  border-radius: 100%;
  display: block;
  margin-right: 1rem;
  color: #fff;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 700;
  text-align: center;
}

.SelectedList li:hover,
.SelectedList li.is-active {
  background-color: #fde6d9;
}

.SelectedList li .Button--remove {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.SelectedList li .Button--remove .Cross svg {
  width: auto;
  height: 1.5rem;
}

.SelectedList li:hover .Button--remove {
  opacity: 0.75;
}

.SelectedList li:hover .Button--remove:hover {
  opacity: 1;
}

.FundList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.FundList li {
  margin-bottom: 1rem;
  font-weight: 400;
}

.FundList li .bullet {
  margin-right: 5px;
}

.SelectedFunds .FundList li {
  position: relative;
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  transition: background-color 0.2s ease-out;
}

.SelectedFunds .FundList li:hover {
  background-color: #fde6d9;
}

.Button--remove {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.Button--remove .Cross svg {
  width: auto;
  height: 1.5rem;
  color: #ef7026;
}

.FundList li:hover .Button--remove {
  opacity: 0.75;
}

.FundList li:hover .Button--remove:hover {
  opacity: 1;
}

.FundSearchResult {
  margin: 2rem 0;
}

.FundSearchResult h3 {
  margin-bottom: 1em;
}

.Select-sunsuper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Select-sunsuper .Button {
  margin-right: 2rem;
}

.Select-sunsuper .ReverseCallout {
  max-width: 40rem;
}

table {
  width: 100%;
  /* table-layout: fixed; */
  margin: 0;
  background: #fff;
  border-collapse: collapse;
  line-height: 1.375;
}

table caption,
table th {
  text-align: left;
}

table th,
table td {
  vertical-align: middle;
  border-right: 1px solid #f79d69;
}

table th {
  padding: 3rem 1.5rem 1.5rem;
  background: #f7f7f7;
  border-bottom: 2px solid #f79d69;
}

table th:first-child {
  border-right: 2px solid #f79d69;
}

table td {
  padding: 1.5rem;
  border-bottom: 1px solid #f79d69;
}

table td:first-child {
  background: #f7f7f7;
  border-right-width: 2px;
}

table tr:last-child td {
  border-bottom: 0;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}

.Table--employers th {
  font-weight: 400;
}

.Table--employers .Tick {
  color: #f79d69;
}

.Table--employers ul,
.Table--employers dl {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.2;
}

.Table--employers ul li {
  margin-bottom: 1rem;
}

.Table--employers dt,
.Table--employers dd {
  display: inline-block;
}

.Table--employers dt {
  font-weight: 400;
}

.Table--employers td:nth-child(4),
.Table--employers td:nth-child(5) {
  text-align: center;
}

.Table--terms-acceptance {
  table-layout: fixed;
  margin: 3rem 0;
}

.Table--terms-acceptance .Button--select-all {
  color: #ef7026;
}



.Loader {
  display: flex;
  align-items: center;
}

.Loader svg {
  width: 3rem;
  height: 3rem;
  padding: 0px;
  transform: rotate(90deg);
}

.Loader .circle {
  fill: transparent;
  stroke: #ef7026;
  stroke-width: 1.5rem;
  animation: 1.5s circle infinite ease-in;
}

.Loader-text {
  margin-left: 1rem;
}

@keyframes circle {
  0% {
    stroke-dasharray: 0 400;
    stroke-dashoffset: 0;
  }
  25% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 400 400;
    stroke-dashoffset: -400;
  }
}

.FormGroup {
  margin-bottom: 1.5rem;
}

.FormGroup > * {
  display: block;
}

.Form-required {
  color: #ee3d48;
}

.Form-hint {
  display: block;
  font-size: 1.3rem;
  font-weight: 300;
}

.FormGroup > .Select,
.FormGroup > input {
  width: 100%;
}

.FormGroup > input.has-error {
  border-color: #ee3d48;
}

.Form-error {
  margin-top: 0.5rem;
  color: #ee3d48;
  font-weight: 400;
  font-size: 1.4rem;
}

.FormGroup + .bsbInfo {
  margin: -1rem 0 2rem;
  font-weight: 400;
  font-size: 1.4rem;
}

.Button {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  line-height: 1;
  text-decoration: none;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
}

.Button:hover {
  cursor: pointer;
}

.Button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.Button--primary,
.Button--secondary {
  text-transform: uppercase;
  padding: 1rem 3rem;
  border-radius: 2rem;
  font-weight: 500;
  border: 2px solid;
}

.Button--primary {
  background: #ef7026;
  border-color: #ef7026;
  color: #fff;
}

.Button--primary:not(.Button--hollow):not(:disabled):hover {
  background-color: #f58544;
  border-color: #f58544;
}

.Button--secondary {
  background: #163655;
  border-color: #163655;
  color: #fff;
}

.Button--hollow {
  background-color: #fff;
}

.Button--primary.Button--hollow {
  color: #ef7026;
}

.Button--primary.Button--hollow:hover {
  color: #f58544;
  border-color: #f58544;
}

.Button--secondary.Button--hollow {
  color: #163655;
}

.Button--underline {
  text-decoration: underline;
  letter-spacing: 0.5px;
  color: #ef7026;
}

.Button--prev,
.Button--next {
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
}

.Button--prev:not(:disabled):before,
.Button--next:not(:disabled):after {
  position: absolute;
  top: 50%;
  opacity: 0;
  width: 1rem;
  height: 1rem;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transition: all 0.2s;
}

.Button--prev:before {
  content: '';
  left: 0;
  border-color: #ef7026;
  transform: translate(25px, -60%) rotate(135deg);
}

.Button--prev:hover:before,
.Button--prev:focus:before {
  transform: translate(15px, -60%) rotate(135deg);
}

.Button--next:after {
  content: '';
  right: 0;
  border-color: #fff;
  transform: translate(-25px, -60%) rotate(-45deg);
}

.Button--next:hover:after,
.Button--next:focus:after {
  transform: translate(-15px, -60%) rotate(-45deg);
}

.Button--prev:hover:before,
.Button--prev:focus:before,
.Button--next:hover:after,
.Button--next:focus:after {
  opacity: 1;
}

.Button--prev span,
.Button--next span {
  display: block;
  transition: inherit;
}

.Button--prev:not(:disabled):hover span,
.Button--prev:not(:disabled):focus span {
  transform: translateX(5px);
}
.Button--next:not(:disabled):hover span,
.Button--next:not(:disabled):focus span {
  transform: translateX(-5px);
}

.ValidationPanel {
  margin: 2rem 0;
  padding: 2rem 1rem;
  border: 1px dashed #ee3d48;
}

.ValidationPanel ul {
  margin-top: 0;
  margin-bottom: 0;
}

.Loader {
  display: flex;
  align-items: center;
}

.Loader svg {
  width: 3rem;
  height: 3rem;
  padding: 0px;
  transform: rotate(90deg);
}

.Loader .circle {
  fill: transparent;
  stroke: #ef7026;
  stroke-width: 1.5rem;
  animation: 1.5s circle infinite ease-in;
}

.Loader-text {
  margin-left: 1rem;
}

@keyframes circle {
  0% {
    stroke-dasharray: 0 400;
    stroke-dashoffset: 0;
  }
  25% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 400 400;
    stroke-dashoffset: -400;
  }
}

.Callout {
  position: relative;
  margin: 1rem;
  padding: 2.5rem;
  border-radius: 4rem;
  background: #ef7026;
  color: #fff;
}

@media (min-width: 768px) {
  .Callout {
    border-radius: 6rem;
    padding: 2.5rem 4.5rem;
  }

  .Callout:before {
    content: '';
    position: absolute;
    display: block;
    color: #ef7026;
    font-size: 5rem;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .Callout--left {
    margin-left: 4rem;
  }
  .Callout--left:before {
    content: '\25C4';
    left: -3rem;
    top: 50%;
    top: calc(50% - 2.5rem);
    text-shadow: none;
  }

  .Callout--right {
    margin-right: 4rem;
  }
  .Callout--right:before {
    content: '\25BA';
    right: -3rem;
    top: 50%;
    top: calc(50% - 2.5rem);
    text-shadow: none;
  }

  .Callout--bottom {
    margin-bottom: 4rem;
  }
  .Callout--bottom:before {
    content: '\25BC';
    left: calc(50% - 2.5rem);
    bottom: -3rem;
    text-shadow: none;
  }
}

.Callout h3 {
  margin: 0;
}

.Callout h3 + p {
  margin-top: 1rem;
}

.Callout a {
  color: #fff;
  text-decoration: none;
}

.Callout a:hover {
  color: #fff;
  text-decoration: underline;
}

.ReverseCallout {
  position: relative;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 3rem;
  border: 2px solid #ef7026;
  background: #fff;
  color: #ef7026;
}

.ReverseCallout:after,
.ReverseCallout:before {
  content: ' ';
  position: absolute;
  right: 100%;
  top: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
}

.ReverseCallout:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 1.5rem;
  margin-top: -1.5rem;
}

.ReverseCallout:before {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ef7026;
  border-width: 1.8rem;
  margin-top: -1.8rem;
}

.ReverseCallout a {
  color: #ef7026;
}

.ReverseCallout a:hover {
  color: #ef7026;
}

.Alert {
  color: #ee3d48;
  font-weight: 400;
}

.Alert svg {
  color: currentColor;
  margin-right: 0.8rem;
  vertical-align: middle;
}

.PaymentOptions ul {
  margin-top: 0;
  font-weight: 500;
}

.Button--add-another.is-active {
  background: #f58544;
  color: #fff;
}

.Button--add-another.is-active:hover {
  color: #fff;
}

.Cross svg path {
  fill: currentColor;
}

.Tick svg path {
  fill: currentColor;
}

.Accordion {
  margin: 1rem 0 2rem;
  border-bottom: 1px solid #ef7026;
  background-color: #f7f7f7;
}

.Accordion-trigger {
  background: none;
  border: 0;
  display: block;
  font-weight: 500;
  margin: 0;
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  position: relative;
  text-align: left;
  color: #4a4a49;
  width: 100%;
  letter-spacing: normal;
  transition: all 0.25s ease-in-out;
}

.Accordion dt:first-child .Accordion-trigger {
  border-radius: 0.3rem 0.3rem 0 0;
}

.Accordion-tick {
  margin-left: 1rem;
  color: #ef7026;
}

.Accordion-icon {
  border: solid #ef7026;
  border-width: 0 2px 2px 0;
  height: 1rem;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-60%) rotate(45deg);
  width: 1rem;
  transition: transform 0.2s ease-in-out;
}

.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
  border-color: #ef7026;
}

.Accordion-trigger[aria-expanded='true'] .Accordion-icon {
  transform: translateY(-50%) rotate(-135deg);
}

.Accordion-title {
  display: inline-block;
  font-weight: 500;
}

.Accordion-subtitle {
  display: block;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-weight: 300;
}

.Accordion-panel {
  margin: 0;
  font-weight: 300;
  height: auto;
  overflow: hidden;
  max-height: 500rem;
  transition: max-height 0.4s ease-in-out;
}

.Accordion-panel[aria-hidden='true'] {
  max-height: 0;
}

.animateIn {
  animation: accordionIn 0.2s normal ease-in-out both;
}

@keyframes accordionIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes accordionOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Accordion-inner {
  padding: 0 1.5rem 1rem;
}

.Accrodion-section {
  margin-bottom: 2.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 90%;
  max-height: 80%;
  max-width: 96rem;
  margin: 0 -50% 0 auto;
  background: #fff;
  overflow: auto;
  box-shadow: 0 1rem 4rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate(-50%, 0);
}

.modal-content--before-open,
.modal-content--before-close {
  transform: translate(-50%, 0);
}

.modal-content--after-open {
  transform: translate(-50%, -50%);
}

.modal-overlay,
.modal-content {
  opacity: 0;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.modal-content--after-open,
.modal-overlay--after-open {
  opacity: 1;
}

.modal-content--before-close,
.modal-overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-header {
  position: relative;
  margin: 3rem;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-body {
  margin: 0 3rem 3rem;
}

.Section--hints {
  display: flex;
}

.Section--hints > div {
  flex: 1 1;
}

@media (min-width: 768px) {
  .Section--hints > div:first-child {
    flex: 2 1;
  }
}

.StatusPanel {
}

.StatusPanel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.StatusPanel li {
  margin-bottom: 0.5rem;
}

.StatusPanel li .Tick {
  margin-right: 1rem;
  color: #ef7026;
}

.StatusPanel li .Cross {
  margin-right: 1.3rem;
  color: #ee3d48;
}

.NextBestActions {
  border: 1px solid #ef7026;
}

.Progress {
  margin: 3rem 0;
}

.Progress-list {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.Progress-list:after {
  content: '';
  display: table;
  clear: both;
}

.Progress-list li {
  flex: 1 1;
  text-align: center;
  position: relative;
  padding: 0 10px;
  font-weight: 300;
  text-decoration: none;
}

.Progress-list li span {
  display: none;
}

@media (min-width: 655px) {
  .Progress-list li span {
    display: inline;
  }
}

.Progress-list li.is-active {
  font-weight: 500;
  color: #ee3d48;
}

/* Progress line */
.Progress-list li:before {
  content: '';
  width: 70%;
  height: 2px;
  background: #ef7026;
  position: absolute;
  left: -35%;
  bottom: 9px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  transform-origin: 0;
}

.Progress-list li.is-finished:before {
  animation: grow 0.3s ease-in-out;
}

@keyframes grow {
  0% {
    transform: scaleX(0);
  }
}

.Progress-list li:first-of-type:before {
  content: none;
}

/* Step dot */
.Progress-list li:after {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  background: #fff;
  border: 2px solid #ef7026;
  border-radius: 100%;
  margin: 5px auto 0 auto;
  transition: background-color 0.3s ease-in-out;
}

.Progress-list li.is-finished:after {
  background: #fff;
  border: 2px solid #ef7026;
  border-radius: 100%;
  background-color: #ef7026;
}

.Progress-list li.is-active:after {
  border-color: #ee3d48;
  background-color: #ee3d48;
}

.Header {
  margin-bottom: 5rem;
}

.Header-logo {
  width: auto;
  height: 50px;
}

